<template>
  <Layout>
    <PageHeader :heading="$t('xpbx.pages.heading.dashboard')" />
    <h3 class="font-bold text-2xl my-4">
      {{ $t("xpbx.pages.subheadings.calls") }}
    </h3>
    <div
      class="flex flex-col lg:flex-row flex-wrap w-full"
      v-if="calls && calls?.length"
    >
      <DashboardCard v-for="(card, index) in calls" :key="index" :data="card" />
    </div>
    <Loader v-else />
    <h3 class="my-4 font-bold text-2xl">
      {{ $t("xpbx.pages.subheadings.sms") }}
    </h3>
    <div
      class="flex flex-col lg:flex-row flex-wrap w-full"
      v-if="messages && messages?.length"
    >
      <DashboardCard
        v-for="(card, index) in messages"
        :key="index"
        :data="card"
      />
    </div>
    <Loader v-else />
  </Layout>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import Layout from "@/modules/xpbx/components/home/layout/index";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import DashboardCard from "@/modules/xpbx/components/dashboard/dashboard-card/index.vue";
import useDashboard from "@/modules/xpbx/composables/useDashboard";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";

export default {
  name: "Dashboard",
  components: {
    Layout,
    PageHeader,
    DashboardCard,
    Loader,
  },
  setup() {
    const t = inject("t");

    const { calls, messages, getDasbboardCalls, getDasbboardMessages } =
      useDashboard();

    onMounted(() => {
      getDasbboardCalls();
      getDasbboardMessages();
    });

    return {
      calls,
      messages,
    };
  },
  onMounted() {
    this.getMainInfo();
  },
  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>
