<template>
  <div class="w-full lg:w-1/2 xl:w-1/4">
    <div
      class="m-5 p-4 rounded-sm border-b-1 border-r-3 border-dark-green bg-white shadow-sm hover:shadow-lg cursor-pointer dashboard-card"
    >
      <div class="">
        <div class="p-3 2xl:p-4">
          <p class="font-bold text-2xl text-center">
            {{ getCardTranslation(data.calltype) }}
          </p>
          <p class="font-bold text-2xl text-center mt-2">
            {{ data.count }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'DashboardCard',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup () {
    const t = inject('t')

    const getCardTranslation = calltype => {
      return t('xpbx.pages.dashboard.texts.' + calltype)
      // switch (calltype) {
      //   case "ipa-out":
      //     return t("xpbx.pages.dashboard.texts.ipa_out");
      //   case "ivr-out":
      //     return t("xpbx.pages.dashboard.texts.ivr_out");
      //   case "pbx-out":
      //     return t("xpbx.pages.dashboard.texts.pbx_out");
      //   case "ccs-out":
      //     return t("xpbx.pages.dashboard.texts.ccs_out");
      //   case "xlinx-out":
      //     return t("xpbx.pages.dashboard.texts.xlinx_out");
      //   case "ivr-inc":
      //     return t("xpbx.pages.dashboard.texts.ivr_inc");
      //   case "miss-out":
      //     return t("xpbx.pages.dashboard.texts.miss_out");
      //   case "miss-inc":
      //     return t("xpbx.pages.dashboard.texts.miss_inc");
      //   case "sms-out":
      //     return t("xpbx.pages.dashboard.texts.sms_out");
      //   case "sms-inc":
      //     return t("xpbx.pages.dashboard.texts.sms_inc");
      //   default:
      //     return "";
      // }
    }

    return {
      getCardTranslation
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-card {
  border: 1px solid rgba(26, 179, 148, 0.5);
}
</style>
